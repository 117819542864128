// ⚠️⚠️⚠️⚠️ DO NOT EDIT ⚠️⚠️⚠️⚠️
/* eslint-disable */

import type * as Types from '../../../schema';

import fetchGraphql from '@/server/helpers/fetchQuery';
import gql from '@/shared/utils/gql';
export type TagsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type TagsQuery = { __typename?: 'Query', features?: { __typename: 'Features', id: string, marketingEnabled?: boolean, marketingEnabledSMS?: boolean, marketingEnabledCall?: boolean, marketingEnabledDirectMail?: boolean, videoNotificationsDisabled?: boolean, uBTC?: boolean, mBTC?: boolean, VIP?: boolean, Insider?: boolean, isAmsUser?: boolean, isVip?: boolean } };

export type TagsFragment = { __typename: 'Features', id: string, marketingEnabled?: boolean, marketingEnabledSMS?: boolean, marketingEnabledCall?: boolean, marketingEnabledDirectMail?: boolean, videoNotificationsDisabled?: boolean, uBTC?: boolean, mBTC?: boolean, VIP?: boolean, Insider?: boolean, isAmsUser?: boolean, isVip?: boolean };

export const TagsFragmentDoc = gql`
    fragment TagsFragment on Features {
  __typename
  id
  marketingEnabled: hasTag(name: "marketingEnabled")
  marketingEnabledSMS: hasTag(name: "marketingEnabledSMS")
  marketingEnabledCall: hasTag(name: "marketingEnabledCall")
  marketingEnabledDirectMail: hasTag(name: "marketingEnabledDirectMail")
  videoNotificationsDisabled: hasTag(name: "videoNotificationsDisabled")
  uBTC: hasTag(name: "uBTC")
  mBTC: hasTag(name: "mBTC")
  VIP: hasTag(name: "VIP")
  Insider: hasTag(name: "Insider")
  isAmsUser: hasTag(name: "Agent System User")
  isVip: hasGroupTag(name: "VIP Manager")
}
    `;
export const TagsQueryDocument = gql`
    query TagsQuery {
  features {
    ...TagsFragment
  }
}
    ${TagsFragmentDoc}`;

export const getTagsQuery = async (variables: TagsQueryVariables) => {
  return fetchGraphql<TagsQuery, TagsQueryVariables>(TagsQueryDocument, "TagsQuery", { variables });
}
'use client';
import { useCallback } from 'react';
import Script from 'next/script';
import getCdnResource from '@/shared/cdn/getCdnResource';
type Props = {
  isLoggedIn: boolean;
  cid: string;
  backUrl: string;
};

const init = (isLoggedIn: boolean, cid: string, backUrl: string) => {
  try {
    window.gib.init({
      cid,
      backUrl,
      gafUrl: '//eu.id.group-ib.com/id.html',
    });

    window.gib.setAuthStatus(
      isLoggedIn ? window.gib.IS_AUTHORIZED : window.gib.IS_GUEST,
    );
  } catch (e) {}
};

const SnippetInitializer: FC<Props> = ({ isLoggedIn, cid, backUrl }) => {
  const onLoadScript = useCallback(() => {
    if (window.gib) {
      init(isLoggedIn, cid, backUrl);
    } else {
      // eslint-disable-next-line immutable/no-mutation
      window.onGIBLoad = () => {
        init(isLoggedIn, cid, backUrl);
      };
    }
  }, [backUrl, cid, isLoggedIn]);
  return (
    <Script
      id="group-ib_snippet"
      strategy="lazyOnload"
      src={getCdnResource('bitcasino/js/group-ib_snippet.js')}
      onLoad={onLoadScript}
    />
  );
};

export default SnippetInitializer;

import { useCallback } from 'react';
import Cookies from 'js-cookie';
import { getSportsbetUpdateCurrencyMutation } from '../../graphql/SportsbetUpdateCurrencyMutation.generated';
import { getUpdateMyDefaultCurrencyMutation } from '../../graphql/UpdateMyDefaultCurrencyMutation.generated';
import getSiteInfo from '@/client/utils/getSiteInfo';
import getCurrency from '@/server/components/Footer/Settings/CryptoRate/getRate/getCurrency';

const useSetWalletCurrency = (
  walletCurrency: CashierCurrency,
  currency: CashierCurrency,
) => {
  const { isBridgeSite } = getSiteInfo();
  const setWalletCurrency = useCallback(async () => {
    if (walletCurrency === currency) {
      return;
    }
    const updatedCurrency = getCurrency(currency);
    if (isBridgeSite) {
      const id = Cookies.get('userPreferenceId');
      getSportsbetUpdateCurrencyMutation({
        input: { id, currency: updatedCurrency as Currency },
      });
    } else {
      getUpdateMyDefaultCurrencyMutation({ currency: updatedCurrency });
    }
  }, [currency, isBridgeSite, walletCurrency]);

  return { setWalletCurrency };
};

export default useSetWalletCurrency;
